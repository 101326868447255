import { createMuiTheme } from '@material-ui/core/styles'
import { black, primary, secondary } from '../../../../src/constants/theme'

const PRIMARY_COLOR = primary
const SECONDARY_COLOR = secondary
const CUSTOM_BLACK = '#082646'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR,
        },
        secondary: {
            main: SECONDARY_COLOR,
        },
        action: {
            disabled: 'lightgrey',
        },
    },

    overrides: {
        MuiButton: {
            root: {
                borderRadius: '0 !important',
                '&$disabled': {
                    color: 'white',
                },
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: SECONDARY_COLOR,
                },
            },
            outlinedSecondary: {
                color: CUSTOM_BLACK,
            },
            outlined: {
                color: CUSTOM_BLACK,
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.65rem',
                padding: 0,
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '0.75rem',
            },
        },
        MuiGrid: {
            item: {
                //paddingBottom: '0 !important',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
            },
        },
        MuiSelect: {
            root: {
                borderRadius: 0,
            },
            select: {
                fontSize: '0.85rem',
            },
            outlined: {
                borderRadius: 0,
            },
        },
    },
})

export default theme
