module.exports = {
    siteMetadata: {
        siteUrl: 'https://avocatsdivorceseparation.ca',
        siteName: 'AvocatsDivorceSeparation',
        title: 'Besoins d’un avocat spécialisé en séparation ou divorce?',
        description: 'Trouvez le bon avocat pour votre séparation ou divorce!',

        gtag: 'G-RBY6LEGYWX',
        gads: 'AW-996648267',
        ga: '',
        splashImgName: 'splash.jpg',
    },
    defaultFormConfig: {
        lawType: 'family-family',
        description: 'Séparation et Divorce',
        hideLawType: true,
        hideOthersInvolved: false,
        hideDescription: true,
        source: 'avocatsdivorceseparation',
        forceEmail: true,
        mobileBackground: true,
        simple: true,
    },
    content: {
        find: 'Trouvez le bon avocat pour votre séparation ou divorce et augmenter vos chances de gagner votre dossier!',
        answer: 'Répondez à quelques questions seulement et JuriGo.ca identifiera le meilleur avocat pour votre dossier de séparation ou divorce.',
        lawyerFor: 'Séparation et Divorce',
    },
}
